<template>
  <div>
    <h2 class="caption text-uppercase">{{ t("title") }}</h2>
    <v-card class="elevation-0" :loading="loading">
      <v-card-text>
        <apexchart type="area" height="257" :options="chartOptions" :series="series" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import chartOptions from "./earningsChartOptions";
import { mapGetters } from "vuex";

/**
 * Блок с графиком заработка
 */
export default {
  props: {
    period: {
      type: String,
      default: "7D"
    }
  },

  data() {
    return {
      loading: true,
      rawData: [],
      chartOptions,
      series: []
    };
  },

  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),
    ...mapGetters("auth", ["apps"])
  },

  watch: {
    period() {
      this.loadData();
    }
  },

  mounted() {
    this.loadData();

    if (this.$vuetify.rtl) {
      chartOptions.xaxis.labels.offsetY = this.$vuetify.rtl ? 65 : 0;
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.earnings_chart." + key, params);
    },

    /**
     * Загрузка данных по датам
     */
    loadData() {
      this.loading = true;
      this.$store.dispatch("dashboard/loadEarningsByDay", { period: this.period }).then(this.processDataLoadResponse);
    },

    /**
     * Обработка данных от сервера
     */
    processDataLoadResponse(response) {
      let rawData = response.data;
      this.series = [];
      let apps = this.apps;

      let appSeries = {};
      apps.forEach(appType => {
        appSeries[appType] = [];
      });

      let dateCount = 0;
      rawData.forEach(row => {
        dateCount++;
        apps.forEach(app => {
          appSeries[app].push({
            x: row.date,
            y: row[app]
          });
        });
      });

      this.updateOptions(dateCount);

      apps.forEach(app => {
        this.series.push({
          name: this.appDomain(app),
          data: appSeries[app]
        });
      });

      this.loading = false;
    },

    /**
     * Обновление настроек графика в зависимости от числа дат
     */
    updateOptions(dateCount) {
      /**
       * В случае если выбран период больше 15 дней,
       * то на графике нужно показывать шкалу со значениями слева (по оси Y),
       * и убрать лейблы у каждого пика графика
       */
      let settings = {};
      if (dateCount > 15) {
        settings = {
          yaxis: { labels: { show: true } },
          dataLabels: { enabled: false }
        };
      } else {
        settings = {
          yaxis: { labels: { show: false } },
          dataLabels: { enabled: true }
        };
      }
      this.chartOptions = Object.assign({}, this.chartOptions, settings);
    }
  }
};
</script>
