<template>
  <div>
    <h2 class="caption text-uppercase">{{ t("title") }}</h2>
    <v-card class="elevation-0">
      <v-card-text>
        <div class="v-data-table theme--light v-data-table elevation-0 mb-2">
          <div class="v-data-table__wrapper">
            <table id="tab2">
              <thead>
                <tr>
                  <th>{{ t("columns.name") }}</th>
                  <th>{{ t("columns.description") }}</th>
                  <th class="text-center">{{ t("columns.status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(appData, appType) in programs">
                  <td>
                    <span :style="{ color: appData.color }" class="text-capitalize">{{ appData.domain }}</span>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="text--secondary text-decoration-underline"
                      @click.prevent="redirectToPlan(appType)"
                    >
                      {{ t("conditions") }}
                    </a>
                  </td>
                  <td class="text-center">
                    <a
                      v-if="appUserId(appType) == null"
                      href="#"
                      class="text-decoration-underline text--secondary"
                      @click.prevent="activateProgram(appType)"
                    >
                      {{ t("status.off") }}
                    </a>
                    <span v-else class="text-success"> {{ t("status.on") }} </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

/**
 * Блок со списком партнерских программ
 */
export default {
  computed: {
    ...mapGetters("app", ["appsData"]),
    ...mapGetters("auth", ["appUserId"]),
    programs() {
      return { fd1: this.appsData["fd1"], fd2: this.appsData["fd2"] };
    }
  },
  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.programs." + key, params);
    },

    /**
     * Активация партнерской программы для указанного приложения
     */
    activateProgram(app) {
      this.$store.dispatch("auth/activateProgram", app);
    },

    /**
     * Переход к странице Conditions and plans
     */
    redirectToPlan(app) {
      this.$router.push({ name: "rates", params: { app } });
    }
  }
};
</script>
