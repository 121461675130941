<template>
  <div>
    <h2 class="caption text-uppercase">{{ t("title") }}</h2>
    <v-card class="elevation-0">
      <v-card-text>
        <v-data-table :headers="headers" :items="items" :loading="loading" hide-default-footer>
          <template #item.date="{ item }">
            <template v-if="item.date == null">
              {{ t("last30days_row_title") }}
            </template>
            <template v-else>
              {{ item.date }}
            </template>
          </template>
        </v-data-table>
        <div class="caption">
          {{ t("notes.copies") }}<br />
          <b>
            {{ t("notes.storage_cost") }}
          </b>
          <br />
          {{ t("notes.unlimited_period") }}
          <br />
          {{ t("notes.calculate_cost") }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
/**
 * Таблица заработка
 */
export default {
  data() {
    return {
      loading: false,
      items: []
    };
  },
  computed: {
    /**
     * Колонки таблицы
     */
    headers() {
      return [
        {
          text: this.t("fields.date"),
          value: "date",
          sortable: false,
          width: "25rem"
        },
        {
          text: this.t("fields.money"),
          value: "money_earned",
          sortable: false
        },
        {
          text: this.t("fields.files_size"),
          value: "files_size",
          sortable: false
        },
        {
          text: this.t("fields.storage_cost"),
          value: "storage_cost",
          sortable: false
        }
      ];
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.earnings_table." + key, params);
    },

    /**
     * Загрузка данных по датам
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("dashboard/loadEarningsByMonth")
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },

    /**
     * Обработка данных от сервера
     */
    processDataLoadResponse(response) {
      this.items = response.data.data;
      this.loading = false;
    },

    /**
     * Обработка ошибки при загрузке данных
     */
    catchDataLoadResponse() {
      this.loading = false;
    }
  }
};
</script>
