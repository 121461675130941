<template>
  <div>
    <v-col cols="12" class="pt-0 mt-0">
      <v-row class="pt-0 mt-0">
        <v-col lg="12" class="pt-0 mt-0 pb-0 mb-0 ">
          <div class="d-flex justify-space-between">
            <div>
              <v-btn
                v-for="value in periods"
                :key="value"
                class="elevation-0 me-2"
                :outlined="value == period"
                x-small
                color="success"
                @click.prevent="selectPeriod(value)"
              >
                {{ t(`periods.${value}`) }}
              </v-btn>
            </div>
            <div>
              <v-icon color="grey" class="pl-3" big @click="refreshTodayData">
                mdi-refresh
              </v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
      <info-blocks :period="period"></info-blocks>
    </v-col>

    <v-col>
      <v-row>
        <v-col lg="7" md="12" sm="12" cols="12">
          <earnings-chart :period="period"></earnings-chart>
        </v-col>

        <v-col lg="5" md="12" sm="12" cols="12">
          <affiliate-programs-block></affiliate-programs-block>
          <file-storage-period-block></file-storage-period-block>
          <unlimited-status-store-files-dialog />
          <unused-files-block />
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="12" md="12">
          <earnings-table></earnings-table>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import InfoBlocks from "@/views/pages/dashboard/InfoBlocks";
import EarningsChart from "@/views/pages/dashboard/EarningsChart";
import EarningsTable from "@/views/pages/dashboard/EarningsTable";
import AffiliateProgramsBlock from "@/views/pages/dashboard/AffiliateProgramsBlock";
import FileStoragePeriodBlock from "@/views/pages/dashboard/FileStoragePeriodBlock";
import UnlimitedStatusStoreFilesDialog from "@/views/pages/dashboard/UnlimitStatusStoreFilesDialog";
import UnusedFilesBlock from "@/views/pages/dashboard/UnusedFilesBlock";

/**
 * Страница Dashboard
 */
export default {
  name: "Dashboard",
  components: {
    InfoBlocks,
    EarningsChart,
    EarningsTable,
    AffiliateProgramsBlock,
    FileStoragePeriodBlock,
    UnlimitedStatusStoreFilesDialog,
    UnusedFilesBlock
  },
  metaInfo() {
    return {
      title: this.$t("titles.dashboard")
    };
  },
  data() {
    return {
      periods: ["7D", "30D", "W", "M"],
      period: "7D"
    };
  },
  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.main." + key, params);
    },

    /**
     * Реакция на смену периода выборки
     */
    selectPeriod(value) {
      this.period = value;
    },

    /**
     * Обновление данных за сегодня
     */
    refreshTodayData() {
      this.$bus.$emit("dashboard:refresh-today-data");
    }
  }
};
</script>
