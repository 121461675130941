<template>
  <v-dialog width="700" persistent :value="storeFilesDialog.visible">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ t("unlimit_status_title") }}
      </v-card-title>

      <v-card-text class="pt-4">
        <v-row style="height: 50px;">
          <span v-html="t('description_reminder_1')"></span>
        </v-row>
        <br>
        <v-row style="height: 50px;">
          <span v-html="t('description_reminder_2')"></span>
        </v-row>
        <v-row>
          <v-select
              v-model="period"
              :items="periods"
              :label="t('select_period_unlimited_status')"
              persistent-hint
          />
        </v-row>
        <v-row>
          {{ t("title_description_unlimited_status") }}
        </v-row>
        <v-row style="margin-top: 10px!important;">
          <span v-html="t('history_activations')"></span>
        </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
          >
          </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn color="primary" @click="sendUnlimitedStatusRequest">
          {{ t("send_request") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CaUnlimitedStatusStoreFilesDialog",
  data() {
    return {
      period: 1,
      headers: [
        { text: this.t("date_start"), value: "activate_date" },
        { text: this.t("period"), value: "period" },
        { text: this.t("date_end"), value: "end_date" },
      ],
      items: []
    }
  },
  computed: {
    storeFilesDialog() {
      return this.$store.state.dashboard.unlimitedStatusStoreFilesDialog;
    },

    /**
     * Варианты периода
     */
    periods() {
      return [
        { text: this.t("periods.one_month"), value: 1 },
        { text: this.t("periods.two_month"), value: 2 }
      ];
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.files_storage_blocks." + key, params);
    },

    /**
     * Загрузка данных
     */
    loadData() {
      this.loading = true;
      this.items = [];
      this.$store.dispatch("dashboard/loadUnlimitedActivationHistory").then(response => {
        response.data.data.forEach(row => {
          this.items.push({
            activate_date: row.activate_date,
            period: this.periods.find(({ value }) => value === row.period).text,
            end_date: row.end_date
          });
        });
      });
    },

    /**
     * Запрос получения безлимитного статуса
     */
    sendUnlimitedStatusRequest() {
      this.$store
              .dispatch("dashboard/sendUnlimitedStatusRequest", {
                period: this.period,
              })
              .then(response => {
                this.loadData();
                this.$store.dispatch("app/showDialog", {
                  type: response.success ? "success" : "error",
                  message: response.success
                          ? this.t("unlimited_status_set")
                          : this.t("unlimited_status_not_set")
                });
              })
              .catch(() => {
                alert(this.t("unlimited_status_not_set"));
              });
    },

    /**
     * Закрыть окно
     */
    closeDialog() {
      this.$store.dispatch("dashboard/closeUnlimitedStatusStoreFilesDialog");
    },
  }
};
</script>
