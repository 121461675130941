<template>
  <v-row>
    <v-col cols="12" lg="3" md="6">
      <v-card class="elevation-0" :loading="loading">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <div>
              <p class="ma-0 text--disabled">{{ t("earnings.title") }}</p>
            </div>
            <div>
              <v-icon color="green lighten-3">mdi-chart-bar-stacked</v-icon>
            </div>
          </div>
          <h4>
            {{ t("earnings.stat_volume", { volume: info.earnings_amount_stat }) }}
          </h4>
          <div class="d-flex justify-space-between flex-wrap align-center">
            <div class="d-flex align-center">
              <span class="success--text">
                {{ t("earnings.today_volume", { volume: info.earnings_amount_today }) }}
              </span>
            </div>
            <div>
              <p class="ma-0">{{ t("earnings.today") }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <v-card class="elevation-0" :loading="loading">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <div>
              <p class="ma-0 text--disabled">{{ t("downloads.title") }}</p>
            </div>
            <div>
              <v-icon color="green lighten-3">mdi-chart-box-plus-outline</v-icon>
            </div>
          </div>
          <h4>{{ info.downloads_count_stat }}&nbsp;</h4>
          <div class="d-flex justify-space-between flex-wrap align-center">
            <div class="d-flex align-center">
              <span class="success--text">
                {{ t("downloads.today_volume", { volume: info.downloads_count_today }) }}
              </span>
            </div>
            <div>
              <p class="ma-0">{{ t("downloads.today") }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <v-card class="elevation-0" :loading="loading">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <div>
              <p class="ma-0 text--disabled">{{ t("sales.title") }}</p>
            </div>

            <div>
              <v-icon color="green lighten-3">mdi-credit-card-check-outline</v-icon>
            </div>
          </div>
          <h4>{{ info.sales_count_stat }}&nbsp;</h4>
          <div class="d-flex justify-space-between flex-wrap align-center">
            <div class="d-flex align-center">
              <span class="success--text">
                {{ t("sales.today_volume", { volume: info.sales_count_today }) }}
              </span>
            </div>
            <div>
              <p class="ma-0">{{ t("sales.today") }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <v-card class="elevation-0 blue darken-1" :loading="loading">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <div>
              <p class="ma-0 white--text">{{ t("balance.title") }}</p>
            </div>
            <div>
              <v-icon color="white">mdi-cash-multiple</v-icon>
            </div>
          </div>
          <h4 class="white--text">
            {{ t("balance.volume", { volume: info.user_available_withdrawal }) }}
          </h4>
          <div class="d-flex justify-space-between flex-wrap align-center">
            <div class="white--text">
              {{ t("balance.hold", { volume: info.user_hold_amount }) }}
            </div>
            <div>
              <a href="#" class="white--text text-decoration-underline" @click.prevent="redirectToPayouts">
                {{ t("balance.link") }}
              </a>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
/**
 * Блоки с суммарной информацией - заработок, покупки, скачивания и баланс
 */
export default {
  props: {
    period: {
      type: String,
      default: "7D"
    }
  },

  data() {
    return {
      loading: true,
      info: {}
    };
  },

  watch: {
    period() {
      this.loadData();
    }
  },

  mounted() {
    this.$bus.$on("dashboard:refresh-today-data", this.loadTodayData);
    this.loadData();
  },

  beforeDestroy() {
    this.$bus.$off("dashboard:refresh-today-data", this.loadTodayData);
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.info_blocks." + key, params);
    },

    /**
     * Загрузка всех данных разом
     */
    loadData() {
      this.loading = true;
      this.$store.dispatch("dashboard/loadInfoBlocksData", { period: this.period }).then(this.processDataLoadResponse);
    },

    /**
     * Обработка данных от сервера при загрузке всех разом
     */
    processDataLoadResponse(response) {
      this.info = response.data;
      this.loading = false;
    },

    /**
     * Загрузка данных только за сегодня
     */
    loadTodayData() {
      this.loading = true;
      this.$store.dispatch("dashboard/loadInfoBlocksTodayData").then(this.processTodayDataLoadResponse);
    },

    /**
     * Обработка данных от сервера при загрузке только за сегодня
     */
    processTodayDataLoadResponse(response) {
      this.info = Object.assign({}, this.info, response.data);
      this.loading = false;
    },

    redirectToPayouts() {
      this.$router.push({ name: "payouts" });
    }
  }
};
</script>
