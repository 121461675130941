<template>
  <div>
    <h2 class="caption pt-4">
      <span class=" text-uppercase">{{ t('title') }}</span>
    </h2>
    <v-card class="elevation-0" :loading="loading">
      <v-card-text>
        <template v-if="!loading">
              <span v-html="t('table_title')"></span>
            <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    density="compact"
            >
              <template #item.period="{ item }">
                  {{ item.period }} {{ t("days") }}
              </template>
              <template #item.sizeFiles="{ item }">
                <a href="#" class="text--secondary text-decoration-underline" @click.prevent="redirectToFileManagerFilter(item.range)">
                  {{ item.sizeFiles }}
                </a>
              </template>
            </v-data-table>
          <template>
            <v-btn
                class="elevation-0 me-2"
                style="margin-top: 10px"
                x-small
                color="success"
                @click.prevent="searchAndRemoveFiles()"
            >
              {{ t(`button_search_and_remove`) }}
            </v-btn>
          </template>
          <div style="margin-top: 10px">
            <span v-html="t('first_info')"></span>
          </div>
          <div style="margin-top: 10px">
            <span>{{ t('second_info') }}</span>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>


import {mapGetters} from "vuex";

/**
 * Блок
 */
export default {
  data() {
    return {
      loading: true,
      redirectToApp: 'file_manager',
      headers: [
        { text: this.t("period"), value: "period",  align: 'center' },
        { text: this.t("count_files"), value: "countFiles",  align: 'center' },
        { text: this.t("size_files"), value: "sizeFiles",  align: 'center' },
        { text: this.t("cost_storage_files"), value: "costStorageFiles",  align: 'center' },
      ],
      items: []
    };
  },
  computed: {
    ...mapGetters("app", ["filemanagerDomain"]),
  },
  mounted() {
    this.loadData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.unused_files_blocks." + key, params);
    },

    /**
     * Загрузка данных
     */
    loadData() {
      this.loading = false;
      this.$store.dispatch("dashboard/loadUnusedFiles").then(response => {
        response.data.forEach((row) => {
          this.items.push({
            period: row.period,
            range: row.range,
            countFiles: row.countFiles,
            sizeFiles: (row.sizeFiles / 1024 / 1024 / 1024).toFixed(2) + " " + this.t("unit_size"),
            costStorageFiles: row.costStorageFiles.toFixed(2) + " $"
          });
        });
      });
    },

    /**
     * Переход на File Manager где откроется окно с фильтрами, без предустановленных значений
     * @param period
     */
    redirectToFileManagerFilter(period) {
      this.setFiltersAndRedirect({
        period: period,
        showFilter: true
      })
    },

    /**
     * Переход на File Manager с фильтрами и предустановленными значениями
     */
    searchAndRemoveFiles() {
      this.setFiltersAndRedirect({
        showFilter: true
      })
    },

    /**
     * Устанавливает куки и делает редирект на File Manager
     * @param data
     */
    setFiltersAndRedirect(data) {
      let app = 'file_manager'
      document.cookie = "filters=" + JSON.stringify(data) + "; domain=." + this.getDomainName(this.filemanagerDomain) + "; path=/";
      this.$store.dispatch("auth/loginToApp", { app });
    },

    /**
     * Получение основного домена.
     * @param hostName
     * @returns {string}
     */
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1).replace('/', '');
    }
  }
};
</script>
