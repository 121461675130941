/**
 * Настройки графика заработка
 */
export default {
  chart: {
    height: 250,
    toolbar: {
      show: false
    }
  },
  xaxis: {
    labels: {
      rotate: -90,
      rotateAlways: true
    }
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  colors: ["#F14004", "#4CA8FF"],
  stroke: {
    width: 2,
    curve: "smooth"
  },
  markers: {
    size: 2
  },
  dataLabels: {
    enabled: true,
    background: {
      enabled: true,
      foreColor: "#fff",
      padding: 2,
      borderRadius: 1,
      borderWidth: 1,
      borderColor: "#fff",
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45
      }
    },
    style: {
      fontSize: "12px",
      fontFamily: "Helvetica, Arial, sans-serif",

      colors: undefined
    }
  }
};
