<template>
  <div>
    <h2 class="caption pt-4">
      <span class=" text-uppercase">{{ t("title") }}</span>
      ( <a href="#" class="text-lowercase" @click.prevent="redirectToFAQ">{{ t("title_link") }}</a> )
    </h2>
    <v-card class="elevation-0" :loading="loading">
      <v-card-text>
        <template v-if="!loading">
          <span class="text-body-2 text-gray-800" v-html="t('term', { normal_term, premium_term })"> </span>
          <br />
          {{ t("next_update", { next_update }) }}
          <br />
          <template>
            <v-btn
                    class="elevation-0 me-2"
                    x-small
                    color="success"
                    @click.prevent="selectUnlimitedStatusForm()"
            >
              {{ t(`select_unlimit_status`) }}
            </v-btn>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
/**
 * Блок с периодом хранения файлов
 */
export default {
  data() {
    return {
      loading: true,
      premium_term: null,
      normal_term: null,
      next_update: null,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.dashboard.files_storage_blocks." + key, params);
    },

    /**
     * Загрузка данных
     */
    loadData() {
      this.loading = true;
      this.$store.dispatch("dashboard/loadStoragePeriod").then(this.processDataLoadResponse);
    },

    /**
     * Обработка данных от сервера
     */
    processDataLoadResponse(response) {
      this.normal_term = response.data.normal_term;
      this.premium_term = response.data.premium_term;
      this.next_update = response.data.next_update;
      this.loading = false;
    },

    redirectToFAQ() {
      this.$router.push({ name: "faq" });
    },

    selectUnlimitedStatusForm() {
      this.$store.dispatch("dashboard/showUnlimitedStatusStoreFilesDialog", {
        onCancel: null,
        onConfirm: null
      });
    }
  }
};
</script>
